import BreadCrumb from '../../../components/BreadCrumb/Index'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import Panel from '../../../components/Panel/Index'

const AdminProjects = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="Projects" />
      <Panel title="Projects" description="Manage Projects"></Panel>
    </AdminLayout>
  )
}
export default AdminProjects
