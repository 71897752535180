import { useEffect } from 'react'
import {
  getAllBuyerProjects,
  getBuyerProject,
} from '../../../../store/buyerProject/buyerProjectReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Index'
import BuyerLayout from '../../../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import Panel from '../../../../components/Panel/Index'

const ShowProject = () => {
  const dispatch = useDispatch()
  const projectId = useParams()
  const project = useSelector((state: any) => state.buyerProjects.project)
  const proposals = useSelector((state: any) => state.proposals.data)

  useEffect(() => {
    dispatch(getBuyerProject(projectId.id))
    dispatch(getAllBuyerProjects(projectId.id))
  }, [])

  const decodeHtmlEntities = (html) => {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = html
    return textarea.value
  }

  if (!project) <Spinner />

  return (
    <BuyerLayout>
      <BreadCrumb title="Projects Details" url={`/buyer/projects`} />
      <Panel
        title="Project Details"
        description="Your Project details and proposals."
      >
        <div className="bg-white dark:bg-gray-950 shadow sm:rounded-lg">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Title
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.title}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Project Budget
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  ${project.budget}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeHtmlEntities(project.description),
                    }}
                  />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Client Website
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.website}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Scope/Type of Project
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.typeOfProject === 'All'
                    ? 'Multiple Projects'
                    : project.typeOfProject}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Skills Required
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.skills}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Primary Technologies Required
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.primaryTech && project.primaryTech.join(', ')}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900 dark:text-white">
                  Secondary Technologies Required
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-white">
                  {project.secondaryTech && project.secondaryTech.join(', ')}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Panel>
      <div>
        {proposals?.map((proposal: any) => (
          <div key={proposal._id}>
            {proposal.description}
            {proposal.budget}
            {proposal.duration}
            {proposal.coverLetter}
            {proposal.status}
          </div>
        ))}
      </div>
    </BuyerLayout>
  )
}
export default ShowProject
