import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../../../routing/PrivateRoute'
import SellerHome from '../Home/Index'
import Stripe from '../../Stripe/Index'
import AccountStatus from '../../Stripe/AccountStatus/Index'
import SellerProjects from '../Projects/Index'
import Tasks from '../Tasks/Index'
import CreateTask from '../Tasks/CreateTask/Index'
import { Fragment } from 'react'
import Onboarding from '../Onboarding/Index'
import SellerRedirect from '../../../routing/SellerRedirect'
import CreateProposal from '../Proposals/CreateProposal/Index'

const SellerRoutes = () => {
  return (
    <Fragment>
      <Routes>
        {/* Seller Routes */}

        <Route
          path="/stripe/connect"
          element={
            <PrivateRoute>
              <Stripe />
            </PrivateRoute>
          }
        />

        <Route
          path="/onboarding"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <Onboarding />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/stripe/callback"
          element={
            <PrivateRoute>
              <AccountStatus />
            </PrivateRoute>
          }
        />

        {/* Seller Project Routes */}
        <Route
          path="/projects"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <SellerProjects />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/projects/:id/proposals/apply"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <CreateProposal />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        {/* Seller Task Routes */}
        <Route
          path="/tasks"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <Tasks />
              </PrivateRoute>
            </SellerRedirect>
          }
        />

        <Route
          path="/tasks/new"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <CreateTask />
              </PrivateRoute>
            </SellerRedirect>
          }
        />
        {/* Seller Routes */}
        <Route
          path="/:username"
          element={
            <SellerRedirect>
              <PrivateRoute>
                <SellerHome />
              </PrivateRoute>
            </SellerRedirect>
          }
        />
      </Routes>
    </Fragment>
  )
}
export default SellerRoutes
