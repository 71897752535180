import { useSelector } from 'react-redux'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import { RootState } from '../../../store'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Panel from '../../../components/Panel/Index'

const Tasks = () => {
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <SellerLayout>
      <BreadCrumb title="Tasks" url={`/seller/${user?.username}`} />
      <Panel
        title="Tasks"
        description="Tasks you have created"
        icon={PlusCircleIcon}
        url={`/seller/tasks/new`}
      ></Panel>
    </SellerLayout>
  )
}
export default Tasks
