import BreadCrumb from '../../../components/BreadCrumb/Index'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import Panel from '../../../components/Panel/Index'

const AdminPlatforms = () => {
  return (
    <AdminLayout>
      <BreadCrumb title="Platforms" />
      <Panel title="Platforms" description="Manage Platforms"></Panel>
    </AdminLayout>
  )
}
export default AdminPlatforms
