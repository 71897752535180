import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountStatus } from '../../../store/stripe/stripeReducer'
import { CurrencyDollarIcon } from '@heroicons/react/20/solid'
import { payoutSettings } from '../../../store/stripe/stripeService'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import { RootState } from '../../../store'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'

// const secondaryNavigation = [
//     { name: 'Last 7 days', href: '#', current: true },
//     { name: 'Last 30 days', href: '#', current: false },
//     { name: 'All-time', href: '#', current: false },
// ]

const Connected = () => {
  const auth = useSelector(
    (state: RootState) =>
      state.auth || JSON.parse(localStorage.getItem('persist:auth') || '{}')
  )

  const { accountBalance } = useSelector((state: RootState) => state.stripe)
  const dispatch = useDispatch()

  const cards = [
    {
      name: 'Pending balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: accountBalance?.pending[0].amount,
      currency: accountBalance?.pending[0].currency,
    },
    {
      name: 'Available balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: accountBalance?.available[0].amount,
      currency: accountBalance?.available[0].currency,
    },
    {
      name: 'Instant Available balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: accountBalance?.instant_available[0].amount,
      currency: accountBalance?.available[0].currency,
    },
  ]

  useEffect(() => {
    if (auth?.user?.stripe_seller?.charges_enabled) {
      dispatch(getAccountStatus(auth.token))
    }
  }, [])

  const handlePayoutSettings = async (e) => {
    e.preventDefault()
    try {
      const response = await payoutSettings(auth.token)
      window.location.href = response.data.url
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <SellerLayout>
      <BreadCrumb
        title="Seller Overview"
        url={`/seller/${auth?.user?.username}`}
      />
      {/* <header className="pb-4 pt-6 sm:pb-6">
                    <div className="mx-auto flex max-w-7xl flex-wrap justify-between items-center gap-6 sm:flex-nowrap">
                        <h1 className="text-lg font-medium leading-6 text-gray-900">
                            Seller Overview
                        </h1>
                        <div className="order-last flex w-full px-4 gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                            {secondaryNavigation.map((item) => (
                                <a key={item.name} href={item.href} className={item.current ? 'text-rose-600' : 'text-gray-700'}>
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="order-last px-4 flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                            <Link
                                to="#"
                                onClick={handlePayoutSettings}
                                className="mr-auto flex items-center gap-x-1 rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                            >
                                Payout Settings
                            </Link>
                            <Link
                                to="/user/stripe/callback"
                                className="ml-auto flex items-center gap-x-1 rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                            >
                                Refresh Balance
                            </Link>
                        </div>
                    </div>
                </header> */}
      <Panel
        title="Seller Overview"
        description="View your seller overview"
        url="#"
        icon={CurrencyDollarIcon}
      >
        <div className="">
          <div className="mt-2 px-4 py-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {cards.map((card) => (
              <div
                key={card.name}
                className="overflow-hidden rounded-lg bg-white dark:bg-gray-950 dark:border boreder-white shadow"
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="truncate text-sm font-medium dark:text-white text-gray-500">
                          {card.name}
                        </dt>
                        <dd>
                          <div className="text-lg font-medium dark:text-white text-gray-900">
                            {card.currency?.toUpperCase()} ${card.amount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-950 px-5 py-3">
                  <div className="text-sm">
                    <Link
                      to={card.href}
                      className="font-medium text-rose-700 dark:text-white hover:text-rose-900"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-end">
            <div className="order-last px-4 flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <Link
                to="#"
                onClick={handlePayoutSettings}
                className="mr-auto flex items-center gap-x-1 rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
              >
                Payout Settings
              </Link>
              <Link
                to="/seller/stripe/callback"
                className="ml-auto flex items-center gap-x-1 rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
              >
                Refresh Balance
              </Link>
            </div>
          </div>
        </div>
      </Panel>
    </SellerLayout>
  )
}
export default Connected
