import { PlusCircleIcon } from '@heroicons/react/24/outline'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import { MouseEvent, useEffect, useState } from 'react'
import { deleteUser, getAllUsers } from '../../../store/user/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

const UsersManagement = () => {
  const dispatch = useDispatch()
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const { users } = useSelector((state: RootState) => state.users)

  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch, shouldRefetch])

  const handleDelete = async (
    e: MouseEvent<HTMLAnchorElement, MouseEvent>,
    userId: any
  ) => {
    e.preventDefault()
    // Display a SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })

    if (result.isConfirmed) {
      await dispatch(deleteUser(userId) as any)
      setShouldRefetch(!shouldRefetch) // Trigger a re-fetch after successful deletion
    }
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Users Management" url="/admin/" />
      <Panel
        title="Users"
        description="View and manage users."
        url="/admin/users/new"
        icon={PlusCircleIcon}
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="-mx-4 sm:-mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Subscription
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-950">
                {users.map((person) => (
                  <tr key={person._id}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:w-auto sm:max-w-none sm:pl-0">
                      {person.first_name} {person.last_name}
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only sm:hidden">Subscription</dt>
                        <dd className="mt-1 truncate text-gray-500 dark:text-white sm:hidden">
                          {person.subscriptions[0]?.status === 'active' ? (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                              Active
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                              Not Subscribed
                            </span>
                          )}
                        </dd>
                        <dt className="sr-only">Location</dt>
                        <dd className="mt-1 truncate dark:text-white text-gray-700">
                          {person.location}
                        </dd>
                        <dt className="sr-only sm:hidden">Email</dt>
                        <dd className="mt-1 truncate text-gray-500 dark:text-white sm:hidden">
                          {person.email}
                        </dd>
                        <dt className="sr-only sm:hidden">Role</dt>
                        <dd className="mt-1 truncate text-gray-500 dark:text-white sm:hidden">
                          {person.role}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                      {person.email}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                      {person.subscriptions[0]?.status === 'active' ? (
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                          Active
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                          Not Subscribed
                        </span>
                      )}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                      {person.role}
                    </td>
                    <td className="flex py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link
                        to={`/admin/users/${person._id}`}
                        className="text-rose-600 hover:text-rose-900 mr-4"
                      >
                        View<span className="sr-only">, {person.name}</span>
                      </Link>
                      <Link
                        to={`/admin/users/${person._id}/edit`}
                        className="text-rose-600 hover:text-rose-900 mr-4"
                      >
                        Edit<span className="sr-only">, {person.name}</span>
                      </Link>
                      {person?.role !== 'ROLE_ADMIN' && (
                        <Link
                          onClick={(
                            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                          ) => handleDelete(e, person._id)}
                          to="#"
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete<span className="sr-only">, {person.name}</span>
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Panel>
    </AdminLayout>
  )
}
export default UsersManagement
