import { useEffect } from 'react'
import { getAllBuyerProjects } from '../../../store/buyerProject/buyerProjectReducer'
import { useDispatch, useSelector } from 'react-redux'
import ProjectCard from '../../../components/Project/ProjectCard/Index'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import { RootState } from '../../../store'
import Panel from '../../../components/Panel/Index'

const BuyerProjects = () => {
  const dispatch = useDispatch()
  const { user, isLoading } = useSelector((state: RootState) => state.auth)
  const { userProjects } = useSelector(
    (state: RootState) => state.buyerProjects
  )

  useEffect(() => {
    dispatch(getAllBuyerProjects())
  }, [isLoading])

  return (
    <BuyerLayout>
      <BreadCrumb title="Projects" url={`/buyer/${user?.username}`} />
      <Panel
        title="Projects"
        description="View and manage projects."
        url="/buyer/projects/new"
        icon={PlusCircleIcon}
      >
        <ul className="divide-y divide-gray-200">
          <ProjectCard userProjects={userProjects} />
        </ul>
      </Panel>
    </BuyerLayout>
  )
}
export default BuyerProjects
