import BreadCrumb from '../../components/BreadCrumb/Index'
import BuyerLayout from '../../components/Layout/BuyerLayout/Index'
import Panel from '../../components/Panel/Index'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useEffect } from 'react'
import { getMe } from '../../store/auth/authReducer'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useFormik } from 'formik'

const UserProfile = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)

  interface FormValues {
    username: string
    first_name: string
    last_name: string
    email: string
    password: string
    confirm_password: string
    role: string
    location: string
    zipcode: string
  }

  const FormValues: FormValues = {
    username: user?.username || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    location: user?.location || '',
    zipcode: user?.zipcode || '',
  }

  const formik = useFormik({
    initialValues: FormValues,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  useEffect(() => {
    dispatch(getMe())
  }, [])

  return (
    <BuyerLayout>
      <BreadCrumb title="Profile Details" url={`/buyer/${user?.username}`} />
      <Panel
        title="Profile Details"
        subtitle="Update your profile details"
        url={`/buyer/${user?.username}`}
        icon={CheckIcon}
      >
        <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="bg-white dark:bg-gray-950 px-4 py-6 sm:p-6">
                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={formik.values.first_name}
                        autoComplete="cc-given-name"
                        {...formik.getFieldProps('first_name')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        value={formik.values.last_name}
                        autoComplete="cc-family-name"
                        {...formik.getFieldProps('last_name')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        name="email-address"
                        id="email-address"
                        autoComplete="email"
                        value={formik.values.username}
                        {...formik.getFieldProps('username')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="email-address"
                        id="email-address"
                        autoComplete="email"
                        value={formik.values.email}
                        {...formik.getFieldProps('email')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        Country
                      </label>
                      <select
                        id="location"
                        name="location"
                        autoComplete="country-name"
                        {...formik.getFieldProps('location')}
                        className="mt-2 block w-full rounded-md border-0 bg-white px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      >
                        <option>Select a location</option>
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                        <option value="Mexico">Mexico</option>
                      </select>
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                      >
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        {...formik.getFieldProps('zipcode')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 dark:bg-gray-800 dark:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-950 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-900"
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </Panel>
    </BuyerLayout>
  )
}
export default UserProfile
